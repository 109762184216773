
import { defineComponent, PropType } from 'vue'
import { instructionsCaller } from '@/mixins/instructionsCaller'
import { InstructionDsp } from '../../../types/instruction_type'
import { InsertionOrderProcessor } from '../../../utils/Processor/InsertionOrderProcessor'
import { briefFieldsConfigMixin } from '@/mixins/briefFieldConfig'

export default defineComponent({
  name: 'DV360CampaignSurcoucheV2',
  mixins: [ instructionsCaller, briefFieldsConfigMixin ],
  props: {
    dsp: {
      type: String as PropType<InstructionDsp>,
      required: true
    }
  },
  data: function () {
    return {
      header: [
        { text: 'Name', value: 'name' },
        { text: 'Health', value: 'health' },
        { text: 'Alerts', value: 'alerts' },
        { text: 'Entities', value: 'entities' }
      ],
      singleSelect: true,
      items: [],
      ioList: [],
      instructions: null,
      insertionOrderProcessor: new InsertionOrderProcessor()
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler: async function () {
        this.instructions = this.data
        try {
          const briefConfig = await this.getBriefFieldsForDsp(this.dsp)
          const toMerge = briefConfig.to_merge
          this.ioList = this.insertionOrderProcessor.mapListIO(
            this.instructions,
            this.$dspConfig[this.dsp],
            toMerge
          )
        } catch (TypeError) {
          console.warn('TypeError mapListIO')
        }
      }
    }
  }
})
